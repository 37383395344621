import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import React, { useEffect } from 'react'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { auth } from '../lib/firebase'
import { useUserData } from '../lib/hooks'
import { Input } from '@/components/elements/Input'
import SubmitButton from '@/components/elements/Button/submit'
import { Layout } from '@/components/Layout'
import { Container } from '@/components/utils'
import { Dialog } from '@/components/elements/Alert/dialog'
import { Checkbox } from '@/components/elements/Checkbox'
import { CheckboxGroup } from '@/components/elements/Checkbox/group'

export async function getStaticProps() {
  const title = 'Login'
  const description = `Accés al panell de control`
  return {
    props: {
      meta: {
        title,
        description,
      },
    },
  }
}

export default function Login({ meta }): JSX.Element {
  const [signInWithEmailAndPassword, loggedInUser, loading, error] =
    useSignInWithEmailAndPassword(auth)
  const router = useRouter()
  const [userInfo] = useUserData()

  useEffect(() => {
    if (userInfo) {
      router.push((router.query.returnUrl as string) || '/admin')
    }
  }, [userInfo, router])
  const { register, handleSubmit } = useForm()

  const submitSignInForm = ({ email, password }) => {
    signInWithEmailAndPassword(email, password)
  }
  return (
    <Layout meta={meta}>
      <div className="py-12 bg-navy-light-100 md:py-36">
        <Container>
          <div className="mx-auto max-w-md text-center">
            <h1 className="font-display text-3xl">
              Accés al panell de control
            </h1>
            <p className="pt-4 font-body text-lg leading-6">
              Introdueix les teves credencials en el següent formulari per
              entrar-hi
            </p>
          </div>
          <form
            onSubmit={handleSubmit(submitSignInForm)}
            className="mt-8 mx-auto px-4 py-4 max-w-md text-navy-900 font-body bg-white rounded-lg md:px-10 md:py-10"
          >
            <Input
              id="email"
              label="Adreça d’email"
              name="email"
              type="email"
              register={register}
              required
            />
            <Input
              className="mt-6"
              id="password"
              label="Contrasenya"
              name="password"
              type="password"
              autoComplete="current-password"
              register={register}
              required
            />

            <CheckboxGroup name="rememberMe" className="hidden mt-6">
              <Checkbox
                className="text-base"
                name="rememberMe"
                register={register}
                id="remember"
                label="Recorda'm"
              />
            </CheckboxGroup>

            <div className="mt-6">
              <SubmitButton
                className="w-full"
                label="Entra"
                isLoading={loading}
              />
            </div>
            {error && (
              <Dialog
                type="error"
                className="mt-6"
                title="error"
                description="user and password dont match"
              />
            )}
          </form>
        </Container>
      </div>
    </Layout>
  )
}
